@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixin.scss";

// default
.base-button {
  &:active,
  &:active:hover,
  &:active:focus,
  &:not([disabled]) {
    height: 49px;
    border: none;
    border-radius: 6px;
    background-color: $blueColor !important;
    margin: 20px 0px;
    cursor: pointer;
    display: block;
    padding: 15px 20px !important;
  }
  span {
    height: 19px;
    font-size: 19px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    text-align: left;
    color: $whiteColor !important;
    @include md {
      font-size: 17px;
    }
    @include xxl {
      font-size: 19px;
    }
  }
  &:hover {
    opacity: 0.8;
  }
}

$marginTop: (
  mt4: 4px,
  mt7: 7px,
  mt10: 10px,
  mt18: 18px,
  mt32: 32px,
  mt33: 33px,
  mt34: 34px,
  mt35: 35px,
  mt36: 36px,
  mt42: 42px,
  mt43: 43px,
  mt44: 44px,
);

$marginBottom: (
  mb10: 10px,
  mb18: 18px,
  mb25: 25px,
  mb34: 34px,
  mb70: 70px,
  mb74: 74px,
  mb114: 114px,
);

// margin
@each$mt, $top in $marginTop {
  .base-button--#{$mt} {
    margin-top: $top !important;
  }
}

@each$mb, $bottom in $marginBottom {
  .base-button--#{$mb} {
    margin-bottom: $bottom !important;
  }
}
