$bgHeader: #f5f7ff;
$bgHeaderHover: hsl(227, 61%, 88%);
$textBlack: #212121;
$borderGray: #d8dae1;
$borderCell: #dcdee4;
$whiteColor: #fff;
$blackColor: #333333;
$blackColor2: #3a3a3a;
$redColor: #d94d4d;
$blueColor: #0d47a1;
$blueColorPagination: #85a2d0;
$grayDisabled: #b0b0b0;
$bgLoading: #f8f8f8ad;
$borderDisabled: #dadce2;
