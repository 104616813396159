@import "./variables.scss";
@import "./mixin.scss";

.base-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination {
  display: flex;
  text-align: center;

  &.ant-table-pagination {
    display: none;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $borderGray;
    border-radius: 3px;

    &:hover {
      background-color: $blueColor;

      a {
        color: $whiteColor;
      }
    }

    &-active {
      background-color: $blueColor;

      a {
        color: $whiteColor;
      }
    }
  }

  &-prev,
  &-next {
    .ant-pagination-item-link {
      &:hover {
        border: 1px solid $blueColor;
      }
    }
  }

  &-disabled {
    .ant-pagination-item-link {
      &:hover {
        border: 1px solid $borderGray;
      }
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $blueColor;
}

.ant-spin-dot-item {
  background-color: $blueColor !important;
}

.page-title {
  background-color: $bgHeader;
  text-align: center;
  padding-bottom: 57px;
  top: 104px;
  border-bottom: 1px solid $borderGray;
}

.main-content-page {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $textBlack;
  padding: 0% 25% !important;
  padding-bottom: 35px !important;
  margin-top: 42px !important;
  @include md {
    padding: 0% 15% 42px !important;
  }
  @include xxl {
    padding: 0% 25% 42px !important;
  }
}

.form {
  margin: auto;
}

.text-center {
  text-align: center;
  padding: 5px 0px;
}

.link-title {
  color: $blueColor !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  font-size: 22px;

  &:hover {
    text-decoration: underline;
  }
  @include md {
    font-size: 20px;
  }
  @include xxl {
    font-size: 22px;
  }
}

.ant-input {
  height: 47px;
  line-height: 1.52;
  width: 507px;
  font-size: 21px;
  border-radius: 5px;
  @include md {
    font-size: 19px;
  }
  @include xxl {
    font-size: 21px;
  }
}
.ant-input-number-lg input {
  height: 47px;
  font-size: 21px;
  @include md {
    font-size: 19px;
  }
  @include xxl {
    font-size: 21px;
  }
}

.ant-picker-input input {
  font-size: 21px;
  @include md {
    font-size: 19px;
  }
  @include xxl {
    font-size: 21px;
  }
}

label.ant-form-item-no-colon {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 5px !important;
  p {
    margin: 2px 0px;
  }
  @include md {
    font-size: 19px;
  }
  @include xxl {
    font-size: 21px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
  min-height: 47px;
}

.ant-select-single .ant-select-selector {
  align-items: center;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
  line-height: 1;
}

.ant-select,
.ant-select-item {
  font-size: 21px !important;
  @include md {
    font-size: 19px !important;
  }
  @include xxl {
    font-size: 21px !important;
  }
}
.text-ellipsis-table {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-upload-list-item-name {
  color: $blueColor;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 0px !important;
  @include md {
    font-size: 19px;
  }
  @include xxl {
    font-size: 21px;
  }

  &:hover {
    text-decoration: underline;
  }
}
.ant-picker-panel-container {
  width: 507px !important;
  .ant-picker-panel {
    width: 100% !important;
    .ant-picker-date-panel {
      width: 100% !important;
      .ant-picker-content {
        width: 100% !important;
      }
    }
    .ant-picker-year-panel {
      width: 100% !important;
    }
    .ant-picker-month-panel {
      width: 100% !important;
    }
  }
}

.link-ellipsis {
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-select-item {
  padding-top: 7px !important;
}
.ant-select-item-option-content {
  padding-top: 7px !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}
