@import "../../assets/styles/variables.scss";

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: $bgLoading;
  .spinner-container {
    left: 50%;
    top: 50%;
    position: absolute;
    .lds-ripple {
      display: inline-block;
      position: relative;
      width: 70px;
      height: 70px;
    }
    .lds-ripple div {
      position: absolute;
      border: 2px solid $blueColor;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
      animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
      0% {
        top: 26px;
        left: 26px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      4.9% {
        top: 26px;
        left: 26px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      5% {
        top: 26px;
        left: 26px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 52px;
        height: 52px;
        opacity: 0;
      }
    }
  }
}
