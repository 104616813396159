@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixin.scss";

$colors: (
  white: $whiteColor,
  black: $blackColor,
  red: $redColor,
  blue: $blueColor,
);

$fontSizeXxl: (
  fs16: 16px,
  fs18: 18px,
  fs19: 19px,
  fs20: 20px,
  fs21: 21px,
  fs22: 22px,
  fs26: 26px,
  fs28: 28px,
  fs32: 32px,
  fs34: 34px,
  fs48: 48px,
  fs54: 54px,
);
$fontSizeMd: (
  fs16: 14px,
  fs18: 16px,
  fs19: 17px,
  fs20: 18px,
  fs21: 19px,
  fs22: 30px,
  fs26: 24px,
  fs28: 26px,
  fs32: 30px,
  fs34: 32px,
  fs48: 46px,
  fs54: 52px,
);

$fontWeights: (
  fw1: 100,
  fw2: 200,
  fw3: 300,
  fw4: 400,
  fw5: 500,
  fw6: 600,
  fw7: 700,
  fw8: 800,
  fw9: 900,
);

$spaces: (
  space5: 5px,
  space8: 8px,
  space16: 16px,
  space26: 26px,
  space32: 32px,
  space38: 38px,
  space48: 48px,
  space64: 64px,
);

$paddingRight: (
  pdr5: 5px,
  pdr8: 8px,
  pdr16: 16px,
  pdr26: 26px,
  pdr32: 32px,
  pdr38: 38px,
);

$paddingLeft: (
  pdl5: 5px,
  pdl8: 8px,
  pdl16: 16px,
  pdl26: 26px,
  pdl32: 32px,
  pdl38: 38px,
  pdl25p: 25%,
);

$paddingTop: (
  pdt8: 8px,
  pdt16: 16px,
  pdt26: 26px,
  pdt32: 32px,
  pdt38: 38px,
  pdt44: 44px,
  pdt48: 48px,
  pdt54: 54px,
  pdt67: 67px,
  pdt77: 77px,
  pdt88: 88px,
  pdt92: 92px,
);

$paddingBottom: (
  pdb8: 8px,
  pdb13: 13px,
  pdb16: 16px,
  pdb21: 21px,
  pdb26: 26px,
  pdb29: 29px,
  pdb32: 32px,
  pdb30: 30px,
  pdb35: 35px,
  pdb38: 38px,
  pdb42: 42px,
  pdb43: 43px,
  pdb45: 45px,
  pdb48: 48px,
);

$lineHeight: (
  lh18: 18px,
  lh19: 19px,
  lh21: 21px,
  lh48: 48px,
  lh54: 54px,
  lh58: 58px,
  lh62: 62px,
  lh70: 70px,
);

// default
.base-text {
  font-size: 21px;
  margin-bottom: 0;
  color: $textBlack;
  white-space: break-spaces;
  line-height: 21px;
  @include md {
    font-size: 19px;
    line-height: 19px;
  }
  @include xxl {
    font-size: 21px;
    line-height: 21px;
  }
}

@each $colorName, $color in $colors {
  .base-text--#{"" + $colorName} {
    color: $color;
  }
}

@include md {
  @each $fs, $fontSize in $fontSizeMd {
    .base-text--#{$fs} {
      font-size: $fontSize;
    }
  }
}

@include xxl {
  @each $fs, $fontSize in $fontSizeXxl {
    .base-text--#{$fs} {
      font-size: $fontSize;
    }
  }
}

@each $fw, $fontWeight in $fontWeights {
  .base-text--#{$fw} {
    font-weight: $fontWeight;
  }
}

// margin
@each$s, $space in $spaces {
  .base-text--#{$s} {
    margin-right: $space;
  }
}

@each$s, $space in $spaces {
  .base-text--#{$s} {
    margin-left: $space;
  }
}

@each$pd, $padding in $paddingRight {
  .base-text--#{$pd} {
    padding-right: $padding;
  }
}

@each$pd, $padding in $paddingLeft {
  .base-text--#{$pd} {
    padding-left: $padding;
  }
}

@each$pd, $padding in $paddingTop {
  .base-text--#{$pd} {
    padding-top: $padding;
  }
}

@each$pd, $padding in $paddingBottom {
  .base-text--#{$pd} {
    padding-bottom: $padding;
  }
}
@each$lh, $line in $lineHeight {
  .base-text--#{$lh} {
    line-height: $line;
  }
}
