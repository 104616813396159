$xs-width: 576px;
$sm-width: 577px;
$md-width: 768px;
$xl-width: 1200px;
$xxl-width: 1600px;

@mixin xs {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}
@mixin sm {
  @media (min-width: #{$xs-width}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$md-width}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$xl-width}) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: #{$xxl-width}) {
    @content;
  }
}
