@import "../../assets/styles/variables.scss";

.not-found {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background-color: $whiteColor;
}
