@import "~antd/dist/antd.css";
@import "./global.scss";

* {
  font-family: "Avenir Next", Avenir, "Nimbus Sans L", Roboto, "Noto Sans",
    "Segoe UI", Arial, Helvetica, "Helvetica Neue", sans-serif !important;
}

body,
html {
  width: 100%;
  height: 100%;
  font-weight: 500;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
}
